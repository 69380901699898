import React, { useState } from 'react'
import Axios from 'axios';
import './styles/Profilebar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


const Profilebar = () => {
  const [runningAttacks, setRunningAttacks] = useState(0)
  const [points, setPoints] = useState(0)
  const [plan, setPlan] = useState(0)
  const [time, setTime] = useState(0)
  const [username, setUsername] = useState(0)

  Axios.post('https://kstress.pro:3001/get-user-data', {
    token: localStorage.getItem('kali_token')
  }).then((response) => {
      if (response.data.username) {
        setUsername(response.data.username)
        setRunningAttacks(response.data.running)
        setPoints(response.data.points)
        setPlan(response.data.plan)
        localStorage.setItem('plan', response.data.plan)
        if (response.data.time > 0) {
          setTime(Math.round(response.data.time / 24))
        }
        else {
          setTime(0)
        }
      } else {
        localStorage.clear()
        setUsername("Username")
        setRunningAttacks(0)
        setPoints(0)
        setPlan("Please Login")
        setTime(0)
      }
  })
  return (
    <div className="profile-bar">
      <div className="avatar">
        <img src="https://i.ibb.co/dgySMS3/avatar.png" alt="avatar" />
      </div>
      <div className="profile-info">
        <p>{username}</p>
        <h1>Subscription:<p>{plan}</p></h1>
        <h1>Expire in:<p>{time} Days</p></h1>
        <h1>Points:<p>{points}</p></h1>
        <h1>Running Attacks:<p>{runningAttacks}</p></h1>
      </div>
      <div className="arrow">
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </div>
  )
}

export default Profilebar