import { useState, useEffect } from 'react';
import axios from 'axios';

const Users = () => {
    const [users, setUsers] = useState();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const { data } = await axios.get('/api/users', { signal: controller.signal });
                if (isMounted) {
                    setUsers(data);
                }
            } catch (err) {
                console.error(err);
            }
        }
        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    return (
        <article>
            <h1>Users</h1>
            {users?.length
                ? (
                    <ul>
                        {users.map((user, i) => <li key={i}>{user?.username}</li>)}

                    </ul>
                ) : <p>No Users to Display</p>
            }
        </article>
    );
};

export default Users