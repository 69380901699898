import React from 'react';
import './styles/login-register.scss';

const TOSModal = ({ closeModal }) => {
    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h1>Terms of Service (TOS) for kStress</h1>
                
                {/* List Example */}
                <div className="tos-list">
                    <ul>
                        <li>
                            <h2>1. Introduction</h2>
                            <p>
                            By accessing and using kStress, you agree to abide by these Terms of Service. Please read them carefully.
                            </p>
                        </li>
                        <li>
                            <h2>2. Acceptable Use</h2>
                            <p>
                                2.1 Users of kStress must only test systems they own or have explicit written permission to test.
                            </p>
                            <p>
                                2.2 Any unauthorized use of this service is strictly prohibited and may result in termination of access and potential legal action.
                            </p>
                        </li>
                        <li>
                            <h2>3. Limitation of Liability</h2>
                            <p>
                                3.1 kStress shall not be held responsible for any misuse of the service by its users.
                            </p>
                            <p>
                                3.2 Users are solely responsible for ensuring they have appropriate permissions and that their actions comply with all applicable laws and regulations.
                            </p>
                        </li>
                        <li>
                            <h2>4. Service Limitations</h2>
                            <p>
                                4.1 kStress provides this service "as-is" without any warranties, express or implied.
                            </p>
                            <p>
                                4.2 kStress reserves the right to suspend or terminate services to any user found violating these terms.
                            </p>
                        </li>
                        <li>
                            <h2>5. Indemnification</h2>
                            <p>
                                Users agree to indemnify and hold harmless kStress from any claims, damages, or losses arising from their use of the service in violation of these terms.
                            </p>
                        </li>
                        <li>
                            <h2>6. Confidentiality</h2>
                            <p>
                                Both parties agree to maintain the confidentiality of any proprietary or sensitive information exchanged during the provision of services.
                            </p>
                        </li>
                        <li>
                            <h2>7. Governing Law</h2>
                            <p>
                                These terms shall be governed by and construed in accordance with the laws of France.
                            </p>
                        </li>
                        <li>
                            <h2>8. Changes to the Terms</h2>
                            <p>
                                kStress  reserves the right to modify these terms at any time. Users will be notified of significant changes, and continued use of the service implies acceptance of the revised terms.
                            </p>
                        </li>
                        <li>
                            <h2>9. Termination</h2>
                            <p>
                                kStress reserves the right to terminate or suspend access to the service for any user found in violation of these terms.
                            </p>
                        </li>
                        <li>
                            <h2>10. Contact Us</h2>
                            <p>
                                If you have any questions about these terms, please contact us on <a href="https://discord.gg/ep22A7Y5fA">Discord</a>.
                            </p>
                        </li>
                    </ul>
                </div>
                
                {/* Close Button */}
                <button onClick={closeModal}>Close</button>
            </div>
        </div>
    );
};

export default TOSModal;