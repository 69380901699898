import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import './styles/Home.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faUserSecret, faBolt, faServer } from '@fortawesome/free-solid-svg-icons'


const Home = () => {

  const [users, setUsers] = useState(0)
  const [attacks, setAttacks] = useState(0)
  const [runningAttacks, setRunningAttacks] = useState(0)
  const [servers, setServers] = useState(0)
  const description = 'Powerful, Smart and Reliable.'
  const [descriptionText, setDescriptionText] = useState('')

  useEffect(() => {
    setTimeout(() => {
    setDescriptionText((value) => value + description.charAt(value.length));
    }, 100);
  }, [descriptionText]);

  // get data from api without token
  useEffect(() => {
    Axios.post('https://kstress.pro:3001/home', {
    }).then((response) => {
      setUsers(response.data.users)
      setAttacks(response.data.completed)
      setRunningAttacks(response.data.running)
      setServers(response.data.servers)
    })
  }, [])

  return (
    <div className="bdy">
      <div className="data-bar">
        <div className="data-item">
          <div className="data-item__title">
            <h1><FontAwesomeIcon icon={faUserSecret} /></h1>
            <p>{users}</p>
            <h3>Users Registered</h3>
          </div>
        </div>
        <div className="data-item">
          <div className="data-item__title">
          <h1><FontAwesomeIcon icon={faBolt} /></h1>
            <p>{attacks}</p>
            <h3>Attacks Completed</h3>
          </div>
        </div>
        <div className="data-item">
          <div className="data-item__title">
          <h1><FontAwesomeIcon icon={faArrowUp} /><FontAwesomeIcon icon={faBolt} /></h1>
            <p>{runningAttacks}</p>
            <h3>Attacks Running</h3>
          </div>
        </div>
        <div className="data-item">
          <div className="data-item__title">
          <h1><FontAwesomeIcon icon={faServer} /></h1>
            <p>{servers}</p>
            <h3>Servers Online</h3>
          </div>
        </div>
      </div>
      <div className="logo">
        <img src={require('./styles/assets/logo.png')} alt="logo" />
        <p>{descriptionText}</p>
      </div>
      <div className="planet1">
        <img src={require('./styles/assets/planet1.png')} alt="planet1" />
      </div>
      {/* <div className="testbypass">
        <h1>Test Bypass</h1>
        <p>Test our bypass by clicking the button below.</p>
        <button>Test Bypass</button>
      </div> */}
    </div>
  )
}
export default Home