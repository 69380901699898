import React from 'react'
import "./styles/About.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'


const About = () => {
  return (
    <div classname="About">
      <div className="Contact">
        <h1>Contact</h1>
        <p>For any questions or concerns, please contact us at:</p>
        <p><a href="https://discord.gg/k7pvXsCg3J"><FontAwesomeIcon icon={faDiscord} /></a></p>
      </div>
      <div className="MethodsExplained">
        <h1>Methods Explained</h1>
        <ul className="method-list">
          <li>
            <h3>Geo-Block Bypass(Proxied)</h3>
            <p>Power: <span style={{ color: "rgb(66, 132, 255)" }}>Depending on proxy list</span></p>
            <p>Bypass Potential: <span style={{ color: "rgb(66, 132, 255)" }}>9</span></p>
            <p>Effective Against: <span style={{ color: "rgb(66, 132, 255)" }}>Servers/Games/Websites</span></p>
            <p>Since last year we recive more and more requests to make something work against the Geo-Block Protections, today, we are happy to share with you our new bypass method, Now you can use your own proxies to mask the location of our servers, this way you may be able to send attacks to a server that only accepts requests from your country.</p>
          </li>
          <li>
            <h3>UDP/TCP Climb</h3>
            <p>Power: <span style={{ color: "rgb(66, 132, 255)" }}>3</span></p>
            <p>Bypass Potential: <span style={{ color: "rgb(66, 132, 255)" }}>8</span></p>
            <p>Effective Against: <span style={{ color: "rgb(66, 132, 255)" }}>Servers/Games/Home Connections</span></p>
            <p>These methods, like the name suggest are increasing the number of packages each second starting from 1mb/s until they reach the full potential, then going back to 1mb/s and starts increasing until the attack is over. Mostly used for games to only rise up the ping keeping the server alive but unplayable</p>
          </li>
          <li>
            <h3>UDP/TCP Hex Climb</h3>
            <p>Power: <span style={{ color: "rgb(66, 132, 255)" }}>5-7</span></p>
            <p>Bypass Potential: <span style={{ color: "rgb(66, 132, 255)" }}>8</span></p>
            <p>Effective Against: <span style={{ color: "rgb(66, 132, 255)" }}>Servers/Games</span></p>
            <p>These methods are using the same technologie as the ones above but sending encoded data instead of normal Plain Text, depending on the target they can slightly improve the power.</p>
          </li>
          <li>
            <h3>TCP/UDP Bigdata</h3>
            <p>Power: <span style={{ color: "rgb(66, 132, 255)" }}>5</span></p>
            <p>Bypass Potential: <span style={{ color: "rgb(66, 132, 255)" }}>7</span></p>
            <p>Effective Against: <span style={{ color: "rgb(66, 132, 255)" }}>Servers/Games/Home Connections</span></p>
            <p>These methods are sending less but bigger packeges as the normal UDP/TCP methods wich makes them very effective against protections that limit the number of packages.</p>
          </li>
          <li>
            <h3>VPN Holder</h3>
            <p>Power:<span style={{ color: "rgb(66, 132, 255)" }}> 2</span></p>
            <p>Bypass Potential: <span style={{ color: "rgb(66, 132, 255)" }}>10</span></p>
            <p>Effective Against:<span style={{ color: "rgb(66, 132, 255)" }}> VPN Connections</span></p>
            <p>This method will send a very small TCP attack to rise up the ping making navigation on the internet to be impossible while keeping the target alive so it won't change ips by itself.</p>
          </li>
          <li>
            <h3>Dynamic IP Holder</h3>
            <p>Power: <span style={{ color: "rgb(66, 132, 255)" }}>1</span></p>
            <p>Bypass Potential: <span style={{ color: "rgb(66, 132, 255)" }}>8</span></p>
            <p>Effective Against: <span style={{ color: "rgb(66, 132, 255)" }}>Home Connections</span></p>
            <p>This method will send a very small UDP attack to rise up the ping making navigation on the internet to be impossible while keeping the target alive so it won't change ips by itself.</p>
          </li>
        </ul>
        <div className="PaymentDetails">
          <ul>
          <h3>You decide how much to pay</h3>
          <li>Our prices are not fixed, you can pay as much as you want, we will always deliver the same quality service.</li>
          <li>Using a new inovative system, customers will use points to send attacks.</li>
          <li>As default we gift customers 2400points each time they renew a subscription.</li>
          </ul>
          <ul>
          <h3>How does it work?</h3>
          <li>One point is equal to 1 second of attack.</li>
          <li>This means that you do not have to pay for the whole month if you are only gonna use 1hour of your attack.</li>
          <li>For a usual use those points should be enough, however if you run out of points you can always buy more from the points shop.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default About