import React, { useState } from 'react'
import Axios from 'axios';
import './styles/Hub.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocket, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

function Layer4() {

    const maxforms = () => {
        let max = 0
        if (localStorage.getItem('plan') === "Free") {
            max = 1
        } else if (localStorage.getItem('plan') === "Basic") {
            max = 3
        } else if (localStorage.getItem('plan') === "Standard") {
            max = 5
        } else if (localStorage.getItem('plan') === "Advanced") {
            max = 7
        } else if (localStorage.getItem('plan') === "Pro") {
            max = 10
        } else if (localStorage.getItem('plan') === "Ultimate") {
            max = 10
        } else if (localStorage.getItem('plan') === "Enterprise") {
            max = 10
        }
        return max
    }

    const [submitStatus, setSubmitStatus] = useState('');
    const [attacks, setAttacks] = useState([
        {
            ip: "",
            port: "",
            time: "",
            method: ""
        },
    ])

    const [formFields, setFormFields] = useState([
        {
            ip: "",
            port: "",
            time: "",
        },
    ])
    const handleFormChange = (event, index) => {
        let data = [...formFields]
        data[index][event.target.name] = event.target.value
        setFormFields(data)
    }

    const submit = (e) => {
        e.preventDefault()

        formFields.forEach((attack) => {
            if (attack.ip === "") {
                setSubmitStatus("Invalid IP Adress.")
                return
            }
            if (attack.port === "") {
                setSubmitStatus("Invalid Port.")
                return
            }
            if (attack.time === "") {
                setSubmitStatus("Time must be more then 0.")
                return
            }
            if (attack.method === "") {
                setSubmitStatus("Please select a method.")
                return
            }
        })


        if (localStorage.getItem('kali_token') === null) {
            setSubmitStatus("You need to login first")
            return
        }
        if (localStorage.getItem('plan') === "free") {
            setSubmitStatus("You need to upgrade your plan")
            return
        }

        Axios.post('https://kstress.pro:3001/layer4', {
            kali_token: localStorage.getItem('kali_token'),
            attacks: formFields
        }).then((response) => {
            if (response.data.message) {
                if (response.data.message === "Invalid token") {
                    localStorage.clear()
                    window.location.href = '/login'
                } else {
                    setSubmitStatus(response.data.message);
                }
            }
            else {
                setAttacks(formFields)
                setFormFields([])
            }
        });
    }
    const addFields = (e) => {
        e.preventDefault()
        if (formFields.length >= maxforms()) {
            setSubmitStatus("You can't add more attacks")
            return
        }
        let object = {
            ip: "",
            port: "",
            time: "",
        }
        setFormFields([...formFields, object])
    }
    const removeAttackWhenTimeIsZero = (index) => {
        let data = [...attacks]
        let time = data[index].time
        // wait until time is 0
        setTimeout(() => {
            data.splice(index, 1)
            setAttacks(data)
        }, time * 1000)
    }
    const removeFields = (index) => {
        let data = [...formFields]
        data.splice(index, 1)
        setFormFields(data)
    }
    return (
        <div className="Layer4-Panel">
            <div className="attacks-list">
                <h1>Attacks</h1>
                {attacks.map((attack, index) => {
                    removeAttackWhenTimeIsZero(index)
                    return (
                        <div className="attack">
                            <h1>{attack.ip}</h1>
                            <p>Port: {attack.port}</p>
                            <p>Path: {attack.path}</p>
                            <p>Time: {attack.time}</p>
                            <p>Method: {attack.method}</p>
                        </div>
                    )
                })}
            </div>
            <form onSubmit={submit}>
                {formFields.map((form, index) => {
                    return (
                        <div className="forms">
                            <input name="ip" placeholder="IP"
                                onChange={event => handleFormChange(event, index)}
                                value={form.ip} />
                            <input name="port" placeholder="Port"
                                onChange={event => handleFormChange(event, index)}
                                value={form.port} />
                            <input name="time" placeholder="Time"
                                onChange={event => handleFormChange(event, index)}
                                value={form.time} />
                            <select className="custom-select" name="method" onChange={event => handleFormChange(event, index)} value={form.method}>
                                <option value="null">Method</option>
                                <option value="udp">UDP</option>
                                <option value="tcp">TCP</option>
                                <option value="udptls">UDP TLS</option>
                                <option value="tcptls">TCP TLS</option>
                                <option value="udpreuse">UDP Reuse</option>
                                <option value="tcpreuse">TCP Reuse</option>
                                <option value="udphex">UDP HeX</option>
                                <option value="tcphex">TCP HeX</option>
                                <option value="udphextls">UDP HeX TLS</option>
                                <option value="tcphextls">TCP HeX TLS</option>
                                <option value="udphexreuse">UDP HeX Reuse</option>
                                <option value="tcphexreuse">TCP HeX Reuse</option>
                                <option value="udpclimb">UDP Climb</option>
                                <option value="tcpclimb">TCP Climb</option>
                                <option value="udptlsclimb">UDP TLS Climb</option>
                                <option value="tcptlsclimb">TCP TLS Climb</option>
                                <option value="udpclimbreuse">UDP Climb Reuse</option>
                                <option value="tcpclimbreuse">TCP Climb Reuse</option>
                                <option value="udphexclimb">UDP HeX Climb</option>
                                <option value="tcphexclimb">TCP HeX Climb</option>
                                <option value="udphexclimbreuse">UDP HeX Climb Reuse</option>
                                <option value="tcphexclimbreuse">TCP HeX Climb Reuse</option>
                                <option value="udpbigdata">UDP BigData</option>
                                <option value="tcpbigdata">TCP BigData</option>
                                <option value="udpbigdatatls">UDP BigData TLS</option>
                                <option value="tcpbigdatatls">TCP BigData TLS</option>
                                <option value="udpbigdatareuse">UDP BigData Reuse</option>
                                <option value="tcpbigdatareuse">TCP BigData Reuse</option>
                                <option value="vpnholder">VPN Holder</option>
                                <option value="dynamicipholder">Dynamic IP Holder</option>
                            </select>
                            <button className="removeField" type="button" onClick={() => removeFields(index)}><FontAwesomeIcon icon={faMinus} /></button>
                        </div>
                    )
                })}
                <button className="addField" onClick={addFields}><FontAwesomeIcon icon={faPlus} /></button>
                <button className="submit" onClick={submit}><FontAwesomeIcon icon={faRocket} /></button>
                <h1 className="Errors">{submitStatus}</h1>
            </form>
            <br />
        </div>
    )
}

export default Layer4