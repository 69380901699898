import React from 'react';
import { Link } from 'react-router-dom'
import './styles/Navbar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faRocket,faUser, faCircleInfo, faGem} from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
  return (
    <div className='sum'>
        <nav className="item">
            <ul className="ul">
                <li>
                    <Link to="/home"><FontAwesomeIcon icon={faHouse} /></Link>
                </li>
                <li>
                    <Link to="/panels"><FontAwesomeIcon icon={faRocket} /></Link>
                </li>
                <li>
                    <Link to="/portal"><FontAwesomeIcon icon={faUser} /></Link>
                </li>
                <li>
                    <Link to="/shop"><FontAwesomeIcon icon={faGem} /></Link>
                </li>
                <li>
                    <Link to="/about"><FontAwesomeIcon icon={faCircleInfo} /></Link>
                </li>
            </ul>
        </nav>
    </div>
  )
}

export default Navbar