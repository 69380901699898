import {React, useState} from 'react';
import './styles/Panels.scss';
// import fa shield
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export const Panels = () => {
  const gaming = () => {
    window.location.href = '/gaming'
  }
  const web = () => {
    window.location.href = '/layer7'
  }
  const layer4 = () => {
    window.location.href = '/layer4'
  }
  const proxied = () => {
    if (localStorage.getItem('plan') && localStorage.getItem('plan') !== "Free" && localStorage.getItem('plan') !== "") {
    window.location.href = '/proxied'
    } else {
      setSubmitStatus('You need a subscription to see this hub!')
    }
  }
  const [submitStatus, setSubmitStatus] = useState('');
  return (
    <div className="Panels">
      <button onClick={gaming} className="Gaming">
        <h2>Gaming</h2>
        <ul>
          <li>FiveM</li>
          <li>RedM</li>
          <li>CS:GO</li>
          <li>Call of Duty</li>
          <li>Metin2</li>
        </ul>
      </button>
      <button onClick={web} className="web">
        <h2>Web</h2>
        <ul>
          <li>Cloudflare</li>
          <li>Post</li>
          <li>Get</li>
          <li>BigData</li>
          <li>Post Forms</li>
        </ul>
      </button>
      <button onClick={(layer4)} className="Layer4">
        <h2>Layer4</h2>
        <ul>
          <li>UDP/TCP</li>
          <li>Dynamic IP Holder</li>
          <li>VPN Holder</li>
          <li>Climb</li>
          <li>UDP/TCP HeX</li>
        </ul>
      </button>
      <button onClick={(proxied)} className="Proxied">
        <h1>{submitStatus}</h1>
        <h2>Proxied</h2>
        <h2 className="new"><FontAwesomeIcon icon={faStar} />New!</h2>
        <ul>
          <li>HTTP/S Post</li>
          <li>HTTP/S Get</li>
          <li>HTTP/S Bigdata</li>
          <li>FiveM</li>
          <li>Cloudflare</li>
        </ul>
      </button>
      <div className="claimer">
        <p>Disclaimer: All methods are created to bypass almost any protection!</p>
      </div>
    </div>
  )
}

export default Panels