import React from 'react'

const Portal = () => {
    if (localStorage.getItem('username') === null) {
        window.location.href = '/login'
    } else {
        window.location.href = '/account'
    }
  return (
    <div>Portal</div>
  )
}

export default Portal