import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Profilebar from './components/Profilebar';
import Navbar from './components/Navbar';
import Panels from './components/Panels';
import Login from './components/Login';
import Register from './components/Register';
import Shop from './components/Shop';
import About from './components/About';
import Account from './components/Account';
import Portal from './components/Portal';
import Admin from './components/Admin';
import Gaming from './components/Gaming';
import Layer7 from './components/Layer7';
import Layer4 from './components/Layer4';
import Proxied from './components/Proxied';
import Home from './components/Home';
import Subscriptions from './components/Subscriptions';
import Points from './components/Points';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Stars from "./components/Stars.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Router>
    <Stars />
    <Routes>
      <Route path="/" element={<><Home/><Profilebar/></>} />
      <Route path="/panels" element={<Panels />} />
      <Route path="/portal"element={<Portal />} />
      <Route path="/account" element={<Account />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/about" element={<About />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/home" element={<><Home/><Profilebar/></>} />
      <Route path="/gaming" element={<><Gaming/><Profilebar/></>} />
      <Route path="/layer7" element={<><Layer7/><Profilebar/></>} />
      <Route path="/layer4" element={<><Layer4/><Profilebar/></>} />
      <Route path="/proxied" element={<><Proxied/><Profilebar/></>} />
      <Route path="/shop" element={<><Shop/><Profilebar/></>} />
      <Route path="/subscriptions" element={<><Subscriptions/><Profilebar/></>} />
      <Route path="/points" element={<><Points/><Profilebar/></>} />
      </Routes>
      <Navbar/>
  </Router>,
);

reportWebVitals();
