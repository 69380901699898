import React from 'react';
import './styles/Points.scss';
import normal from './styles/assets/normal.png';
import premium from './styles/assets/premium.png';
import axios from 'axios';

const buy = (type) => {
    const token = localStorage.getItem('kali_token');
    return () => {
        axios.post('https://kstress.pro:3001/buy', {
            token: token,
            type: type
        }).then((res) => {
            // if res.data contains invoice_url then redirect to invoice_url
            if (res.data.invoice.invoice_url) {
                window.location.href = res.data.invoice.invoice_url;
            }
        })
    }
}

export const Points = () => {
    return (
        <div className="Points">
            <div className="plans">
                <div className="normal">
                    <div onClick={buy("2k")} className="pointplan">
                        <div className="cover"><img src={normal} alt="normal"></img>
                            <div className="details">
                                <h2>2.000 Points</h2>
                                <p>€ 5,00</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={buy("5k")} className="pointplan">
                        <div className="cover"><img src={normal} alt="normal"></img>
                            <div className="details">
                                <h2>5.000 Points</h2>
                                <p>€ 10,00</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={buy("15k")} className="pointplan">
                        <div className="cover"><img src={normal} alt="normal"></img>
                            <div className="details">
                                <h2>15.000 Points</h2>
                                <p>€ 25,00</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="premium">
                    <div onClick={buy("30k")} className="pointplan">
                        <div className="cover"><img src={premium} alt="premium"></img>
                            <div className="details">
                                <h2>30.000 Points</h2>
                                <p>€ 50,00</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={buy("50k")} className="pointplan">
                        <div className="cover"><img src={premium} alt="premium"></img>
                            <div className="details">
                                <h2>50.000</h2>
                                <p>€ 75,00</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={buy("100k")} className="pointplan">
                        <div className="cover"><img src={premium} alt="premium"></img>
                            <div className="details">
                                <h2>100.000 Points</h2>
                                <p>€ 130,00</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={buy("200k")} className="pointplan">
                        <div className="cover"><img src={premium} alt="premium"></img>
                            <div className="details">
                                <h2>200.000 Points</h2>
                                <p>€ 200,00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Points