import React from 'react'
import './styles/Account.scss'

function getUserData(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({ token: token })
    };
    fetch('https://kstress.pro:3001/get-user-data', requestOptions)
        .then(response => response.json())
        .then(data => {
            return data
        })
}

const Account = () => {
    const token = localStorage.getItem('token')
    const data = getUserData(token)
    console.log(data)
    return (
        <div>
        <div className="account-details-window">
            <h1 className="Title">Account</h1>
            <h2 className="username">{localStorage.getItem('username')}</h2>
            <h2>Email: {localStorage.getItem('email')}
                <button className="change-email-button" onClick={() => {
                    window.location.href = '/change-email'
                }}>Change Email</button>
            </h2>
            <h2>
                Password: ********
                <button className="change-password-button" onClick={() => {
                    window.location.href = '/change-password'
                }}>Change Password</button>
            </h2>
            <button className="logout-button" onClick={() => {
                localStorage.clear()
                window.location.href = '/login'
            }}>Logout</button>
        </div>
        </div>
    )
}

export default Account
export { getUserData }