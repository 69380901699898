import React, { useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom'
import './styles/Navbar.scss'
import './styles/login-register.scss'
import TOSModal from './TOSModal';


export const Register = () => {

    const [usernameReg, setUsernameReg] = useState('');
    const [emailReg, setEmailReg] = useState('');
    const [passwordReg, setPasswordReg] = useState('');
    const [password2Reg, setPassword2Reg] = useState('');
    const [promo_codeReg, setPromo_codeReg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isTOSAccepted, setIsTOSAccepted] = useState(false);

    const handleTOSAcceptance = () => {
        setIsTOSAccepted(!isTOSAccepted);
    };

    const renderTOSLink = () => (
        <span>
            I have read and agree the <span className="tos-link" onClick={handleTOSClick}>Terms of Service</span>.
        </span>
    );

    const handleTOSClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [registerStatus, setRegisterStatus] = useState('');
    const register = (e) => {
        e.preventDefault();
        Axios.post('https://kstress.pro:3001/register', {
            username: usernameReg,
            email: emailReg,
            password: passwordReg,
            password2: password2Reg,
            promo_code: promo_codeReg
        }).then((response) => {
            if (response.data.message) {
                if (response.data.message === "User registered") {
                    window.location.href = '/login';
                } else {
                    setRegisterStatus(response.data.message);
                }
            }
        });
    };

    return (
        <div className="auth-form-container-register">
            <form className="register-form" onSubmit={register}>
                <h1>Register</h1>
                <label htmlFor="username">Username</label>
                <input type="username" placeholder="username" name="username" id="username" onChange={(e) => { setUsernameReg(e.target.value) }} />
                <label htmlFor="email">Email</label>
                <input type="email" placeholder="kstress@gmail.com" name="email" id="email" onChange={(e) => { setEmailReg(e.target.value) }} />
                <label htmlFor="password">Password</label>
                <input type="password" placeholder="*******" name="password" id="password" onChange={(e) => { setPasswordReg(e.target.value) }} />
                <label htmlFor="password2">Password</label>
                <input type="password" placeholder="*******" name="password2" id="password2" onChange={(e) => { setPassword2Reg(e.target.value) }} />
                <label htmlFor="Referral">Referral</label>
                <input type="Referral" placeholder="Code" name="Referral" id="Referral" onChange={(e) => { setPromo_codeReg(e.target.value) }} />
                <div className="tos-checkbox-container">
                    <input
                        type="checkbox"
                        id="tosCheckbox"
                        checked={isTOSAccepted}
                        onChange={handleTOSAcceptance}
                    />
                    {renderTOSLink()}
                </div>
                {showModal && <TOSModal closeModal={closeModal} />}
                <button className="submit-btn" type="submit" disabled={!isTOSAccepted}>
                    Register
                </button>
            </form>
            <Link to="/login"> Already have an account? Login here</Link>
            <h1 className="Errors">{registerStatus}</h1>
        </div>
    );
};

export default Register;