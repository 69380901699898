import { Link } from 'react-router-dom'
import Users from './Users'
const Admin = () => {
    return (
        <div>
            <h1>Admin</h1>
            <br />
            <Users />
            <br />
            <div className="flexGrow">
                <Link to="/users">Users</Link>
            </div>
        </div>
    )
}

export default Admin