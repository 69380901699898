import React from 'react'
import './styles/Shop.scss'

const subscriptions = () => {
  window.location.href = '/subscriptions'
}
const points = () => {
  window.location.href = '/points'
}
const scanners = () => {
  window.location.href = '/scanners'
}

const Shop = () => {
  return (
    <div>
          <div className="Shop">
      <button onClick={subscriptions} className="subscriptions">
        <h2>Subscriptions</h2>
        <p>The subscription will select the attack power you get.</p>
      </button>
      <button onClick={points} className="points">
        <h2>Points</h2>
        <p>The points represents the boot time you can use for attacks.</p>
      </button>
      <button onClick={(scanners)} className="scanners">
        <h2>Scanners</h2>
        <p>Coming Soon...</p>
      </button>
    </div>
    </div>
  )
}

export default Shop