import React from 'react';
import './styles/Subscriptions.scss';
import normal from './styles/assets/normal.png';
import premium from './styles/assets/premium.png';
import axios from 'axios';

const buy = (type) => {
    const token = localStorage.getItem('kali_token');
    return () => {
        axios.post('https://kstress.pro:3001/buy', {
            token: token,
            type: type
        }).then((res) => {
            // if res.data contains invoice_url then redirect to invoice_url
            if (res.data.invoice?.invoice_url) {
                window.location.href = res.data.invoice.invoice_url;
            } else {
                window.location.href = '/login';
            }
        })
    }
}

export const Subscriptions = () => {
    return (
        <div className="Subscriptions">
            <div className="normal">
                <div className="disclaimer">
                    <p>Recommended against low RAM-memory servers!</p>
                </div>
                <div className="plans">
                    <div onClick={buy("basic")} className="plan">
                        <div className="cover"><img src={normal} alt="normal"></img>
                            <ul>
                                <h1>Basic</h1>
                                <li>Simultaneous attacks: <span style={{ color: "rgb(66, 132, 255)" }}>3</span></li>
                                <li>Power: <span style={{ color: "rgb(66, 132, 255)" }}>1GBPS</span></li>
                                <li>Packets per second: <span style={{ color: "rgb(66, 132, 255)" }}>10.000</span></li>
                                <p>€5,00</p>
                            </ul>

                        </div>
                    </div>
                    <div onClick={buy("standard")} className="plan">
                    <div className="cover"><img src={normal} alt="normal"></img>

                            <ul>
                                <h1>Standard</h1>
                                <li>Simultaneous attacks: <span style={{ color: "rgb(66, 132, 255)" }}>5</span></li>
                                <li>Power: <span style={{ color: "rgb(66, 132, 255)" }}>3GBPS</span></li>
                                <li>Packets per second: <span style={{ color: "rgb(66, 132, 255)" }}>25.000</span></li>
                                <p>€10,00</p>
                            </ul>

                        </div>
                    </div>
                    <div onClick={buy("advanced")} className="plan">
                    <div className="cover"><img src={normal} alt="normal"></img>

                            <ul>
                                <h1>Advanced</h1>
                                <li>Simultaneous attacks: <span style={{ color: "rgb(66, 132, 255)" }}>7</span></li>
                                <li>Power: <span style={{ color: "rgb(66, 132, 255)" }}>5GBPS</span></li>
                                <li>Packets per second: <span style={{ color: "rgb(66, 132, 255)" }}>100.000</span></li>
                                <p>€25,00</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="premium">
                <div className="disclaimer">
                    <p>Recommended for dedicated servers running on lots of ram memory!</p>
                </div>
                <div className="plans">
                    <div onClick={buy("pro")} className="plan">
                    <div className="cover"><img src={premium} alt="premium"></img>
                            <ul>
                                <h1>Pro</h1>
                                <li>Simultaneous attacks: <span style={{ color: "rgb(66, 132, 255)" }}>10</span></li>
                                <li>Power: <span style={{ color: "rgb(66, 132, 255)" }}>10GBPS</span></li>
                                <li>Packets per second: <span style={{ color: "rgb(66, 132, 255)" }}>500.000</span></li>
                                <p>€50,00</p>
                            </ul>
                        </div>
                    </div>
                    <div onClick={buy("ultimate")} className="plan">
                    <div className="cover"><img src={premium} alt="premium"></img>

                            <ul>
                                <h1>Ultimate</h1>
                                <li>Simultaneous attacks: <span style={{ color: "rgb(66, 132, 255)" }}>10</span></li>
                                <li>Power: <span style={{ color: "rgb(66, 132, 255)" }}>25GBPS</span></li>
                                <li>Packets per second: <span style={{ color: "rgb(66, 132, 255)" }}>1.000.000</span></li>
                                <p>€100,00</p>
                            </ul>

                        </div>
                    </div>
                    <div onClick={buy("enterprise")} className="plan">
                    <div className="cover"><img src={premium} alt="premium"></img>

                            <ul>
                                <h1>Enterprise</h1>
                                <li>Simultaneous attacks: <span style={{ color: "rgb(66, 132, 255)" }}>10</span></li>
                                <li>Power: <span style={{ color: "rgb(66, 132, 255)" }}>50GBPS</span></li>
                                <li>Packets per second: <span style={{ color: "rgb(66, 132, 255)" }}>Over 2.000.000</span></li>
                                <p>€200,00</p>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscriptions