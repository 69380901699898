import React, { useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom'
import './styles/Navbar.scss'
import './styles/login-register.scss'

export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [loginStatus, setLoginStatus] = useState('');

    const login = (e) => {
        e.preventDefault();
        Axios.post('https://kstress.pro:3001/auth', {
            username: username,
            password: password
        }).then((response) => {
            if (response.data.message) {
                setLoginStatus(response.data.message);
            }
            else {
                localStorage.setItem('username', response.data[0].username);
                localStorage.setItem('plan', response.data[0].plan);
                localStorage.setItem('time', response.data[0].time / 24);
                localStorage.setItem('points', response.data[0].points);
                localStorage.setItem('email', response.data[0].email);
                localStorage.setItem('kali_token', response.data[0].kali_token);
                window.location.href = '/home';
            }
        });
    };

    return (
        <div className="auth-form-container">
            <form className="login-form">
                <h1>Login</h1>
                <input onChange={(e) => {setUsername(e.target.value)}} type="username" placeholder="User" name="username" id="username" />
                <input onChange={(e) => {setPassword(e.target.value)}} type="password" placeholder="Password" name="password" id="password" />
                <button className="Login-btn" onClick={login}> Login </button>
            </form>
            <Link to="/register"> You don't have an account? Create one here</Link>
        <h1 className="Errors">{loginStatus}</h1>
        </div>
    );
};

export default Login;